/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/assets/css/main.css';

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    window.history.scrollRestoration = 'manual';
    const currentPosition = getSavedScrollPosition(location, location.key);
    if (!currentPosition) {
        window.scrollTo(0, 0);
    } else {
        window.setTimeout(() => {
            window.requestAnimationFrame(() => {
                window.scrollTo(...currentPosition);
            });
        }, 0);
    }

    return false;
};